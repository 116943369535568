<template>
  <div v-loading="loading" element-loading-text="加载..." element-loading-background="rgba(255, 255, 255, .5)">
    <div @click="openUrlLink" class="phone">城市id:{{ cityCode }} 平台名称:{{ plateformname }} 电话号码:{{ umpUserPhone }} 平台列表:{{ jumpUserId }}</div>
  </div>
</template>
<script>
import { getPlateformList } from "@/api/index";
export default {
  data() {
    return {
      loading: false,
      cityCode: "", //城市Id
      plateformname: "", //平台名称
      jumpUserPhone: "", //电话号码
      jumpUserId: "", //用户Id
      plateformList: [], //平台列表
    };
  },
  mounted() {
    this.cityCode = this.$route.query.cityCode;
    this.plateformname = this.$route.query.plateformname;
    this.jumpUserPhone = this.$route.query.jumpUserPhone;
    this.jumpUserId = this.$route.query.jumpUserId;
    if (this.cityCode) {
      this.initData();
    } else {
      console.log("没有携带数据");
    }
  },
  methods: {
    initData() {
      this.loading = true;
      getPlateformList({
        cityCode: this.cityCode,
      })
        .then((res) => {
          if (res.data.code == 200) {
            this.plateformList = res.data.data;
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    // 点击跳转约约注册网页
    openUrlLink() {
      window.location.href = "https://short2.yueyuechuxing.cn/gGi1rg";
    },
  },
};
</script>
<style lang="less" scoped>
.phone {
  height: 100vh;
  width: 100%;
  background-color: #ff0000;
}
</style>
