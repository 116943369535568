import axios from "axios";
export const basePath = 'https://devsjzm.weilango.com';
const http = {
    path(_path) {
        return basePath + _path;
    },
    get(path, data) {
        return axios.get(this.path(path), {
            params: data
        })
    },
    post(path, data) {
        return axios.post(this.path(path), data)
    }
}

export default http;